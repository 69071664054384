import Home from "./pages/Home";

const App = () => {
	return (
		<div className="w-screen h-screen bg-secondary">
			<Home />
		</div>
	);
};

export default App;
