import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Blossom from "./pages/Blossom";
import Photography from "./pages/Photography";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import SAS from "./pages/SAS";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
	},
	{
		path: "blossom",
		element: <Blossom />,
	},
	{
		path: "photography",
		element: <Photography />,
	},
	{
		path: "about",
		element: <About />,
	},
	{
		path: "gallery",
		element: <Gallery />,
	},
	{
		path: "sas",
		element: <SAS />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
		<Analytics />
	</React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
