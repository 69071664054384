import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import ReactPlayer from "react-player";
import heroImage from "../assets/gallery/Screenshot 2023-10-10 at 11.33 1.png";
import userResearchImage from "../assets/gallery/Frame 3.png";
import initialDesignsImage from "../assets/gallery/Frame 4.png";
import metaphorImage from "../assets/gallery/Frame 5.png";
import timelineViewImage from "../assets/gallery/Frame 6.png";
import usabilityTestingImage from "../assets/gallery/Frame 7.png";
import petalDiagramImage from "../assets/gallery/Frame 15.png";
import finalDesignImage from "../assets/gallery/galleryAppMockupsArtboard.png";
import finalDesignVideo from "../assets/gallery/galleryAppBackground4K.mp4";
import { useEffect } from "react";
import { ChevronLeft, ChevronUp } from "lucide-react";

const images = {
	heroImage,
	userResearchImage,
	initialDesignsImage,
	metaphorImage,
	timelineViewImage,
	usabilityTestingImage,
	petalDiagramImage,
	finalDesignImage,
	finalDesignVideo,
};

const content = `
## Background

<div class="flex gap-16">

<div class="flex flex-col w-[50%]">

#### Project Overview
This project was originally created for a course called CS 2701: Startup Lab as part of the Georgia Tech Study Abroad Program in Berlin, Germany. As a result, this case study has an emphasis on entrepreneurship.

</div>

<div class="flex flex-col w-[50%]">

#### Project Brief
Because this was a course on startups, there was no brief provided to us. As a tech-focused group of individuals, we chose to focus on a question at the intersection of art and technology.

</div>

</div>

# What will the future of design licensing look like?
With the recent rise in popularity of non-fungible tokens (NFTs), the technology to securely purchase art digitally was already present. But as we dug deeper, we couldn't find a specialized traditional marketplace that connected designers and buyers on a personal level while also retaining intellectual property (IP) protection.

## User Research
Across the project duration, we interviewed more than 100 people on the streets of Berlin, especially at flea markets where we could connect with local artists and designers. We wanted to better understand their process of selling their artifacts, and we also wanted to learn about the customers buying these artifacts. Here are some questions we asked the two groups:

![User research](userResearchImage)

## Key Insights

<div class="flex gap-16 text-center">

<div class="flex flex-col w-1/3">

#### Our "buyers" are actually just regular people.
Our original vision included a group of buyers with large amounts of reach and resources who could partner with local artists. We realized that this type of buyer is generally employed at corporations with restrictions on purchasing art, meaning our group of buyers would instead be anyone with an interest in art and some money to spend.

</div>

<div class="flex flex-col w-1/3">

#### IP protection is questioned but generally yearned for.
Many of the artists we spoke to at flea markets specifically avoided online platforms like eBay and Etsy because they were worried about their art being taken or reproduced without credit. They were skeptical about an IP solution but certainly seemed receptive to one.

</div>

<div class="flex flex-col w-1/3">

#### Most customers do not care about your technology.
In our case, whenever our backing technology — the blockchain — was brought up, it produced only indifferent or puzzled faces. Customers will only be impressed by a technology if it does its job in the actual product.

</div>

</div>

# A pivot!
From our learnings, we found that a marketplace would not be a great fit because of its inaccessibility to casual users. Instead, we wanted to pivot to a social app, one that still connects creators and buyers and, most importantly, preserves IP.

![Petal diagram](petalDiagramImage)

## Ideation
Below is our petal diagram, grouping the many social, design, and media apps out there. At the time, there were no apps that covered all four categories pictured.

## Design Criteria
When our team moved on to the first design phase, I knew that I wanted to implement some uncommon yet integral design criteria that would elevate our concept to more than "just another social content app."

<div class="flex gap-16 text-center mt-8">

<div class="flex flex-col w-1/3">

#### Creation and consumption, balanced.
In order to create a platform that pleases both creators and consumers, I believe the two actions must be balanced in terms of the app's composition.

</div>

<div class="flex flex-col w-1/3">

#### Less doomscrolling, more appreciation.
Unlike timeline-based apps, I wanted to implement a stopping point so users would hopefully spend less time using the app "just because." Additionally, I focused on putting the art first — no distractions allowed.

</div>

<div class="flex flex-col w-1/3">

#### A personal goal: playful interaction design.
With so many social media and art board apps out there, I wanted to get creative with the interactions throughout my design. As you'll see later in the case study, I played around with imaginative home page and navigation patterns.

</div>

</div>

## Initial Designs
Applying the principle of balancing creation and consumption, I wanted to represent the two actions with equal weight, so the home screen was initially split into two similar screens for posting and browsing, but I soon realized they could be consolidated into a single screen with a toggle.

On the home screen, the user is presented with a list of extremely specific areas of art, and they choose one of these "tags" to begin exploring. However, as much as I enjoyed the symmetry of this design, user flows showed that choosing details about your work before actually uploading it is very unnatural, so this was changed after the first iteration of wireframes.

![Initial designs](initialDesignsImage)

# Sprinkling in a metaphor.
The layout of tags was effective but not impressive. To better represent what it is truly like to experience a niche area of art, I turned to the best real-life example of this: art museums! "Tags" were now transformed into virtual "rooms" that could be entered in an interactive, spatial manner, as seen below.

![Art museum metaphor](metaphorImage)

###### Playing around with the metaphor of entering "rooms" in an art museum.

![Timeline view](timelineViewImage)

###### Getting messy with the Timeline view.

## Initial Usability Testing
Below are the screens presented to a group of five users in our initial usability testing phase who said they use a number of the apps listed in the petal diagram (Instagram, Pinterest, Behance, etc.).

![Usability testing](usabilityTestingImage)

We found that users enjoyed the experience of entering a "room," but they did not like how they had to go back and forth to the home screen to explore other genres of art, which was intentional to reduce distractions and focus on the current "room." We also learned that they did not immediately recognize the grouped navigation bar as a way to interact with the app. Thus, both of these more engaging design patterns were swapped with more typical ones.

# Radial menus?!
Radial menus are scarcely used in mobile designs, but they are my favorite menu design because of their symmetry and the fact that they stay out of the way until you need them. This was especially important in the case of Gallery because I wanted to create an immersive experience free of clutter, focusing just on the content, and I also hoped to address the main usability gripe of the current navigation design, so I moved the app's navigation along with post reactions to a single, universally-accessible radial menu.

## Final Design

![Final design](finalDesignVideo)

The final design incorporates the idea of rooms by using a freeform spatial navigation, allowing users to browse through the corners of the art world. When a user clicks on a topic, they enter the virtual room and are presented with posts that might be in an art gallery for the same topic. The viewport remains locked to each post to encourage the appreciation of art rather than mindless scrolling, and, to reduce distractions, the navigation and actions like "appreciate" and "save" are tucked away in a radial menu.

![Final design](finalDesignImage)

## Reflections

Although I was quite happy with the final product, there are a few key takeaways that I have kept in mind since this project:

<div class="flex gap-16 text-center mt-8">

<div class="flex flex-col w-1/3">

#### Be flexible with the idea.
When designing a novel idea, even if the problem might be definitive, the first solution is not always the right one, which I learned first-hand through the pivot from a marketplace to a social platform.

</div>

<div class="flex flex-col w-1/3">

#### Separate the POVs of the designer and the user.
This may seem somewhat obvious, but admittedly it was something I lost sight of. Although hidden menus and unusual interactions can be fun, to a new user, they can also present a huge learning curve.

</div>

<div class="flex flex-col w-1/3">

#### Next time: consider more user flows.
If I had more time, I would have wanted to investigate and create alternative user flows besides just the "content viewing" flow, as it would have allowed me to have a more thorough picture of the app's purpose.

</div>

</div>
`;

const Gallery = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="flex flex-col w-full min-h-screen items-center p-4 lg:p-20 gap-y-8 lg:gap-y-20 bg-[#69a0ba]">
			<div className="flex flex-col lg:flex-row header w-full max-w-[1000px] p-4 lg:p-20 bg-[#69a0ba] rounded-b-[50px]">
				<div className="flex flex-col w-full lg:min-w-[400px] lg:max-w-[400px]">
					<Link to="/">
						<div className="flex w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] rounded-full absolute top-0 left-0 m-4 items-center justify-center text-black">
							<ChevronLeft size={24} />
						</div>
					</Link>
					<h1 className="text-6xl lg:text-[120px] font-medium mb-4 tracking-tight lg:mt-0 mt-12">
						Gallery.
					</h1>
					<div className="details grid grid-cols-2 w-full lg:w-[385px] gap-y-2 gap-x-4 text-sm lg:text-base">
						<p className="font-semibold text-right">Created For</p>
						<p>CS 2701: Startup Lab</p>
						<p className="font-semibold text-right">Project Duration</p>
						<p>10 weeks (2023)</p>
						<p className="font-semibold text-right">Role</p>
						<p>Design Lead</p>
						<p className="font-semibold text-right">Team Members</p>
						<div className="flex flex-col leading-tight mt-[2px]">
							<a
								href="https://www.linkedin.com/in/harish-viswanathan/"
								className="hover:underline"
								target="_blank"
								rel="noopener noreferrer"
							>
								Harish Viswanathan
							</a>
							<a
								href="https://avaye.netlify.app/"
								className="hover:underline"
								target="_blank"
								rel="noopener noreferrer"
							>
								Avaye Dawadi
							</a>
							<a
								href="https://www.linkedin.com/in/aaravg04/"
								className="hover:underline"
								target="_blank"
								rel="noopener noreferrer"
							>
								Aarav Gupta
							</a>
						</div>
						<p className="font-semibold text-right">Tools Used</p>
						<p>Figma</p>
						<p className="font-semibold text-right">Skills</p>
						<div className="flex flex-col leading-tight mt-[2px]">
							<p>Competitive Analysis</p>
							<p>Usability Research</p>
							<p>Interaction Design</p>
							<p>Prototyping</p>
						</div>
					</div>
				</div>
				<div className="flex w-full items-center justify-center mt-8 lg:mt-0">
					<img
						src={heroImage}
						alt="hero"
						className="w-full object-contain max-w-[250px]"
					/>
				</div>
			</div>
			<div className="body w-full pt-8 lg:pt-10 max-w-[1000px] p-4 lg:p-20 bg-[#B5E8FF] rounded-[50px]">
				<Markdown
					rehypePlugins={[rehypeRaw]}
					components={{
						h1: ({ node, ...props }) => (
							<h1 className="text-4xl font-bold my-8 text-center" {...props} />
						),
						h2: ({ node, ...props }) => (
							<h2 className="text-3xl font-bold mb-6 mt-4" {...props} />
						),
						h3: ({ node, ...props }) => (
							<h3 className="text-2xl font-semibold mb-2" {...props} />
						),
						h4: ({ node, ...props }) => (
							<h4
								className="text-2xl font-semibold mb-2 leading-tight"
								{...props}
							/>
						),
						h5: ({ node, ...props }) => (
							<h5 className="text-2xl font-medium mb-2" {...props} />
						),
						h6: ({ node, ...props }) => (
							<h6
								className="text-sm italic text-center mt-[-32px] mb-8"
								{...props}
							/>
						),
						p: ({ node, ...props }) => (
							<p className="mb-4 leading-tight text-neutral-800" {...props} />
						),
						img: ({ node, ...props }) => {
							const imageSrc = images[props.src] || props.src;
							if (imageSrc.endsWith(".mp4")) {
								return (
									<ReactPlayer
										url={imageSrc}
										controls
										className="my-12 rounded-2xl mx-auto"
									/>
								);
							}
							return (
								<img
									src={imageSrc}
									alt={props.alt}
									className="h-full object-contain my-12 max-h-[500px] rounded-2xl mx-auto"
								/>
							);
						},
					}}
				>
					{content}
				</Markdown>
			</div>
			<div className="w-full flex justify-end mt-8">
				<div className="fixed bottom-4 right-4 w-12 h-12 lg:w-16 lg:h-16 bg-[#FF5C00] rounded-full flex items-center justify-center text-black cursor-pointer">
					<ChevronUp
						size={24}
						onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
					/>
				</div>
			</div>
		</div>
	);
};

export default Gallery;
