import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Home = () => {
	const [isHovered, setIsHovered] = useState(-1);
	const [showPasswordPopup, setShowPasswordPopup] = useState(false);
	const [showIncorrectPasswordPopup, setShowIncorrectPasswordPopup] =
		useState(false);
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const imageVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
	};

	const handleSASClick = (e) => {
		e.preventDefault();
		setShowPasswordPopup(true);
	};

	const handlePasswordSubmit = (e) => {
		e.preventDefault();
		if (
			password.trim() === process.env.REACT_APP_SAS_PASSWORD_SEP24 ||
			password.trim() === process.env.NEXT_PUBLIC_SAS_PASSWORD_SEP24
		) {
			navigate("/sas", { state: { isAuthenticated: true } });
			setShowPasswordPopup(false);
		} else {
			setShowIncorrectPasswordPopup(true);
		}
		setPassword("");
	};

	return (
		<div className="flex flex-col lg:flex-row p-4 lg:p-20 min-h-screen bg-secondary">
			<div className="content flex flex-col w-full lg:min-w-[500px] lg:max-w-[500px] h-full justify-between">
				<div className="header flex mb-8 lg:mb-0 pt-4 pl-4">
					<p className="text-xl lg:text-2xl font-light leading-7 lg:leading-8 text-pretty">
						<span className="font-garamond text-3xl italic font-semibold tracking-tighter text-primary">
							Arjun Dewan
						</span>{" "}
						is a <span className="font-semibold">design engineer</span> who
						loves crafting intuitive digital experiences. He currently studies{" "}
						{/* <a className="underline" href="https://cm.lmc.gatech.edu/"> */}
						Computational Media {/* </a>{" "} */}
						at Georgia Tech.
					</p>
				</div>
				<div className="body flex flex-col divide-y-2 divide-gray">
					<div className="work flex flex-col py-10">
						<p className="text-xl lg:text-2xl font-semibold mb-4 ml-4">Work</p>
						<div className="cards flex flex-col">
							<Link
								to="/blossom"
								className="card flex p-4 justify-between hover:bg-neutral-100 hover:rounded-2xl"
								onMouseEnter={() => setIsHovered(0)}
								onMouseLeave={() => setIsHovered(-1)}
							>
								<div className="flex flex-col w-1/2">
									<p className="text-lg lg:text-xl font-semibold">Blossom</p>
									<p className="text-lg lg:text-xl font-light leading-5">
										Web Browser for Research
									</p>
								</div>
								<div className="details flex flex-col text-right w-1/2 justify-between">
									<p className="text-lg lg:text-xl text-neutral-500">Startup</p>
									<p className="text-lg lg:text-xl text-neutral-500 leading-5">
										2023 - Present
									</p>
								</div>
							</Link>
							<Link
								to="/sas"
								className="card flex p-4 justify-between hover:bg-neutral-100 hover:rounded-2xl"
								onMouseEnter={() => setIsHovered(1)}
								onMouseLeave={() => setIsHovered(-1)}
								onClick={handleSASClick}
							>
								<div className="flex flex-col">
									<p className="text-lg lg:text-xl font-semibold">SAS</p>
									<p className="text-lg lg:text-xl font-light">
										Design Systems + GenAI
									</p>
								</div>
								<div className="details flex flex-col text-right">
									<p className="text-lg lg:text-xl text-neutral-500">
										Internship
									</p>
									<p className="text-lg lg:text-xl text-neutral-500">2024</p>
								</div>
							</Link>
						</div>
					</div>
					<div className="projects flex flex-col py-10">
						<p className="text-xl lg:text-2xl font-semibold mb-4 ml-4">
							Projects
						</p>
						<div className="cards flex flex-col">
							<Link
								to="/gallery"
								className="card flex p-4 justify-between hover:bg-neutral-100 hover:rounded-2xl"
								onMouseEnter={() => setIsHovered(2)}
								onMouseLeave={() => setIsHovered(-1)}
							>
								<div className="flex flex-col">
									<p className="text-lg lg:text-xl font-semibold">Gallery</p>
									<p className="text-lg lg:text-xl font-light">
										Mobile App Case Study
									</p>
								</div>
								<div className="details flex flex-col text-right">
									<p className="text-lg lg:text-xl text-neutral-500">Concept</p>
									<p className="text-lg lg:text-xl text-neutral-500">2023</p>
								</div>
							</Link>
							{/* <a
								className="card flex p-4 justify-between hover:bg-neutral-100 hover:rounded-2xl"
								href="https://www.startupexchangegt.org/"
								target="_blank"
								rel="noopener noreferrer"
								onMouseEnter={() => setIsHovered(3)}
								onMouseLeave={() => setIsHovered(-1)}
							>
								<div className="flex flex-col">
									<p className="text-xl font-semibold">Startup Exchange GT</p>
									<p className="text-xl">Design Direction</p>
								</div>
								<div className="details flex flex-col text-right">
									<p className="text-xl text-neutral-500">Student Org</p>
									<p className="text-xl text-neutral-500">2022 - 2024</p>
								</div>
							</a> */}
						</div>
					</div>
					<div className="more flex flex-col py-10">
						{/* <p className="text-2xl font-semibold mb-4 ml-4">More</p> */}
						<div className="cards flex flex-col">
							<Link
								to="/photography"
								className="card flex p-4 justify-between hover:bg-neutral-100 hover:rounded-2xl"
								onMouseEnter={() => setIsHovered(4)}
								onMouseLeave={() => setIsHovered(-1)}
							>
								<div className="flex flex-col">
									<p className="text-lg lg:text-xl font-semibold">
										Photography
									</p>
									{/* <p className="text-xl">Web Browser for Research</p> */}
								</div>
								<div className="details flex flex-col text-right">
									{/* <p className="text-xl text-neutral-600">Concept</p>
									<p className="text-xl text-neutral-600">2023</p> */}
								</div>
							</Link>
							<Link
								to="/about"
								className="card flex p-4 justify-between hover:bg-neutral-100 hover:rounded-2xl"
								onMouseEnter={() => setIsHovered(5)}
								onMouseLeave={() => setIsHovered(-1)}
							>
								<div className="flex flex-col">
									<p className="text-lg lg:text-xl font-semibold">About</p>
									{/* <p className="text-xl">Design Direction</p> */}
								</div>
								<div className="details flex flex-col text-right">
									{/* <p className="text-xl text-neutral-600">Student Org</p>
									<p className="text-xl text-neutral-600">2024</p> */}
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="image flex-col mt-8 lg:mt-0 lg:ml-20 w-full justify-center">
				<div className="flex w-full h-full items-center">
					{isHovered === 0 && (
						<motion.div
							className="p-4 bg-neutral-100 rounded-3xl"
							initial="hidden"
							animate="visible"
							variants={imageVariants}
						>
							<img
								src={require("../assets/home-images/blossom.png")}
								alt="Blossom"
								className="w-full h-full object-contain rounded-[8px]"
							/>
						</motion.div>
					)}
					{isHovered === 1 && (
						<motion.div
							className="p-4 bg-neutral-100 rounded-3xl"
							initial="hidden"
							animate="visible"
							variants={imageVariants}
						>
							<img
								src={require("../assets/home-images/sas.png")}
								alt="SAS"
								className="w-full h-full object-contain rounded-[8px]"
							/>
						</motion.div>
					)}
					{isHovered === 2 && (
						<motion.div
							className="p-4 bg-neutral-100 rounded-3xl"
							initial="hidden"
							animate="visible"
							variants={imageVariants}
						>
							<img
								src={require("../assets/home-images/gallery.png")}
								alt="Gallery"
								className="w-full h-full object-contain rounded-[8px]"
							/>
						</motion.div>
					)}
					{isHovered === 3 && (
						<motion.div
							className="p-4 bg-neutral-100 rounded-3xl"
							initial="hidden"
							animate="visible"
							variants={imageVariants}
						>
							<img
								src={require("../assets/home-images/sx.png")}
								alt="Startup Exchange GT"
								className="w-full h-full object-contain rounded-[8px]"
							/>
						</motion.div>
					)}
					{isHovered === 4 && (
						<motion.div
							className="p-4 bg-neutral-100 rounded-3xl"
							initial="hidden"
							animate="visible"
							variants={imageVariants}
						>
							<img
								src={require("../assets/home-images/photography.jpg")}
								alt="Photography"
								className="w-full h-full object-contain rounded-[8px]"
							/>
						</motion.div>
					)}
					{isHovered === 5 && (
						<motion.div
							className="p-4 bg-neutral-100 rounded-3xl"
							initial="hidden"
							animate="visible"
							variants={imageVariants}
						>
							<img
								src={require("../assets/home-images/about.png")}
								alt="About"
								className="w-full h-full object-contain rounded-[8px]"
							/>
						</motion.div>
					)}
				</div>
			</div>
			<div to="/">
				<div className="fixed bottom-4 right-4 p-8 bg-[#FF5C00] rounded-full"></div>
			</div>
			{showPasswordPopup && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
					<div className="flex flex-col bg-white p-6 rounded-lg w-[90%] max-w-[300px] items-center">
						<div className="flex mb-4">
							<p className="text-xl font-semibold">
								This case study is password protected.
							</p>
						</div>
						<form className="w-full" onSubmit={handlePasswordSubmit}>
							<input
								type="password"
								className="flex rounded w-full px-2 py-1 mb-2 bg-neutral-100"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								autoComplete="off"
							/>
							{showIncorrectPasswordPopup && (
								<div className="flex w-full mb-4 justify-center">
									<p className="text-red-600 bg-red-100 px-3 rounded-lg">
										Incorrect password
									</p>
								</div>
							)}
							<div className="flex justify-end">
								<button
									type="button"
									className="mr-2 px-4 py-2 text-gray-600"
									onClick={() => setShowPasswordPopup(false)}
								>
									Cancel
								</button>
								<button
									type="submit"
									className="px-8 py-2 bg-[#FF5C00] text-white rounded"
								>
									Enter
								</button>
							</div>
						</form>
					</div>
				</div>
			)}
		</div>
	);
};

export default Home;
